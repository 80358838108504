import React from 'react'
import { Flex, Box, chakra, Button } from "@chakra-ui/react";
import { Link } from 'react-router-dom';
import getData from '../../data';
import { keys } from '../../data';
import _ from 'lodash';
const ViewCards = ({ props }) => {


  let showOnlineStudy = getData(props.data.school, keys.LOGIN_STUDENT_LINK)
  let coachingWebsite = props.data.school.website_template == 2


  const cards = [
    { title: 'Online Admission', desc: 'You can now easily apply for admission, via filling the Online Admission Form.', color: 'orange', link: '/admission/online' },

  ]

  if (showOnlineStudy && coachingWebsite) {
    cards.push({ title: 'Online Study', desc: 'You can now take classes from our world class faculty at anytime any place from your phone.', color: 'green', link: showOnlineStudy })
  }
  if (!coachingWebsite) {
    cards.push({ title: 'Academic Session', desc: 'Our curriculum is designed to provide the best possible education to each of our students through a comprehensive program.', color: 'green', link: '/about' })
  }
  if(coachingWebsite){
    cards.push(
     { title: 'Our Achievements', desc: 'The mission of our institute is to focus on learning for every student every day. This vision is reflected in both our achievement and growth. ', color: 'blue', link: '/achievements' },
   )
 }
 else{
   cards.push(
     { title: 'Our Achievements', desc: 'The mission of our school is to focus on learning for every student every day. This vision is reflected in both our achievement and growth. ', color: 'blue', link: '/achievements' },
   )
 }
  
  return (
    <div>
      <Flex

        p={[4, 8, 6, 8]}
        w="full"
        flexWrap="wrap"
        top={["280px", "", "550px", "650px"]}
        zIndex={["1"]}
        position={["absolute", "static", "absolute", "absolute"]}
        alignItems="center"
        justify="center"
      >
        {
          showOnlineStudy && coachingWebsite ? _.map(cards, (card, i) => (
            <InfoCard key={i + 1} title={card.title} desc={card.desc} color={card.color} link={card.link} />
          )) : coachingWebsite ? _.map(cards.slice(0, 2), (card, i) => (
            <DualCard key={i + 1} title={card.title} desc={card.desc} color={card.color} link={card.link} />
          )) : _.map(cards, (card, i) => (
            <InfoCard key={i + 1} title={card.title} desc={card.desc} color={card.color} link={card.link} />
          ))

        }
        {/* {
          showOnlineStudy?_.map(cards, (card, i) => (
            <InfoCard key={i + 1} title={card.title} desc={card.desc} color={card.color} link={card.link} />
          )):_.map(cards.slice(0, 2), (card, i) => (
            <DualCard key={i + 1} title={card.title} desc={card.desc} color={card.color} link={card.link} />
          ))
        } */}

        {/* <Box
        mx={["3px", "8px"]}
        p={["8px", "20px", "10px"]}
        bg="#f4c193"
        rounded="lg"
        boxShadow={["10px 5px 12px #f5c294", "10px 25px 12px #f5c294"]}
        borderRadius="20px"
        alignSelf="center"
      >
        <Box>
          <chakra.h1
            
            fontSize={["sm", "20px", "20px", "40px"]}
            fontWeight="bolder"
            textAlign="center"
            mt={2}
            color="black"
          >
            Academic Session
          </chakra.h1>
          <chakra.p
            px={["1px", "2px", "2px", "3px"]}
            fontSize={["10px", "10px", "15px", "20px"]}
            
            mt={2}
            textAlign="center"
            color="black"
            noOfLines={[2, 4]}
          >
            Lorem 2 ipsum dolor sit amet, consectetur adipisicing elit. Odio
            eligendi similique exercitationem optio libero vitae accusamus
            cupiditate laborum eos.
          </chakra.p>
       
        </Box>

        <Box my="2px" align="center" justify="center">
          <Button
            size={["sm", "lg"]}
            fontSize={["10px", "10px", "15px", "20px"]}
            bg="#F3904F"
            rounded="md"
            p={["5px", "6px", "6px", "8px"]}
            _hover={{
              bg: "#f4c193",
            }}
          >
            Read more
          </Button>
        </Box>
      </Box>

      
      <Box
        mx={["3px", "8px"]}
        p={["8px", "20px", "10px"]}
        bg="#f4c193"
        boxShadow={["10px 5px 12px #f5c294", "10px 25px 12px #f5c294"]}
        rounded="lg"
        borderRadius="20px"
        alignSelf="center"
      >
        <Box>
          <chakra.h1
            
            fontSize={["sm", "20px", "20px", "40px"]}
            fontWeight="bolder"
            textAlign="center"
            mt={2}
            color="black"
          >
            Academic Session
          </chakra.h1>
          <chakra.p
            px={["1px", "2px", "2px", "3px"]}
            fontSize={["10px", "10px", "15px", "20px"]}
            
            mt={2}
            textAlign="center"
            color="black"
            noOfLines={[2, 4]}
          >
            Lorem 2 ipsum dolor sit amet, consectetur adipisicing elit. Odio
            eligendi similique exercitationem optio libero vitae accusamus
            cupiditate laborum eos.
          </chakra.p>
        </Box>
        <Box align="center" justify="center">
          <Button
            size={["sm", "lg"]}
            fontSize={["10px", "10px", "15px", "20px"]}
            bg="#F3904F"
            rounded="md"
            p={["5px", "6px", "6px", "8px"]}
            _hover={{
              bg: "#f4c193",
            }}
          >
            Read more
          </Button>
        </Box>
      </Box> */}
      </Flex>
    </div>
  )
}

const InfoCard = ({ title, desc, color, link }) => {
  const colors = {
    'green': { bg: '#43c065', boxShadow: ["8px 8px 2px #b0e4be", "8px 8px 2px #b0e4be"] },
    'blue': { bg: '#2b5788', boxShadow: ["8px 8px 2px #a6b8ce", "8px 8px 2px #a6b8ce"] },
    'orange': { bg: '#f89d34', boxShadow: ["8px 8px 2px #fcd5aa", "8px 8px 2px #fcd5aa"] }
  }
  return (
    <Flex
      w={["80em", "5em", "14em", "18em", "20em", "28em"]}
      h={["8em", '14em', '14em', '15em', '15em', '18em']}
      my="0.4em"
      mx={["4px", "15px", "8px", "15px"]}
      p={["3px", "20px", "15px"]}
      // bgColor={color + '.300'}
      // boxShadow='2xl green.400'
      // boxShadow={boxShadows[color] ? boxShadows[color] : ["10px 5px 12px #636adf" , "10px 25px 12px #636adf"]}
      rounded="lg"
      borderRadius="20px"
      alignSelf="center"
      {...colors[color]}
      flexDirection='column'
    >
      <Box flex={1}>
        <chakra.h3

          fontSize={["1em", "20px", "1.2em", "1em", "30px", "40px"]}
          fontWeight="bold"
          textAlign="center"
          mt={2}
          color="white"
        >
          {title}
        </chakra.h3>
        <chakra.p
          px={["1px", "2px", "1em", "0.8em", "3px"]}
          fontSize={["10px", "0.8em", "0.8em", "0.8em", "18px", "20px"]}

          mt={2}
          textAlign="center"
          color="white"
          noOfLines={[3, 4, 6, 4]}

        >
          {desc}
        </chakra.p>
      </Box>
      <Box align="center" justify="center" my='8px'>
{
  title && title == 'Online Study'?
  
  <Button as={Link} 
          onClick={() => window.open(link)}
          size={["sm", "lg"]}
          fontSize={["10px", "10px", "15px", "0.8em", "18px", "20px"]}
          // bg="#F3904F"
          rounded="md"
          p={["5px", "6px", "6px", "8px"]}
          _hover={{
            bg: color + '.200',
            color: color + '.700'
          }}
          bgColor={color + '.100'}
          colorScheme={color}
          variant='ghost'
        >
          View more
        </Button>
  
  :


  <Button as={Link} to={link}
         
          size={["sm", "lg"]}
          fontSize={["10px", "10px", "15px", "0.8em", "18px", "20px"]}
          // bg="#F3904F"
          rounded="md"
          p={["5px", "6px", "6px", "8px"]}
          _hover={{
            bg: color + '.200',
            color: color + '.700'
          }}
          bgColor={color + '.100'}
          colorScheme={color}
          variant='ghost'
        >
          View more
        </Button>
}

        {/* <Button as={Link} to={link}
          onClick={() => window.open(link)}
          size={["sm", "lg"]}
          fontSize={["10px", "10px", "15px", "0.8em", "18px", "20px"]}
          // bg="#F3904F"
          rounded="md"
          p={["5px", "6px", "6px", "8px"]}
          _hover={{
            bg: color + '.200',
            color: color + '.700'
          }}
          bgColor={color + '.100'}
          colorScheme={color}
          variant='ghost'
        >
          View more
        </Button> */}
      </Box>
    </Flex>
  )
}



const DualCard = ({ title, desc, color, link }) => {
  const colors = {
    'green': { bg: '#43c065', boxShadow: ["8px 8px 2px #b0e4be", "8px 8px 2px #b0e4be"] },
    'blue': { bg: '#2b5788', boxShadow: ["8px 8px 2px #a6b8ce", "8px 8px 2px #a6b8ce"] },
    'orange': { bg: '#f89d34', boxShadow: ["8px 8px 2px #fcd5aa", "8px 8px 2px #fcd5aa"] }
  }
  return (
    <Flex
      w={["80em", "5em", "20em", "23em", "30em", "32em"]}
      h={["8em", '14em', '13em', '12em', '16em', '16em']}
      my="0.4em"
      mx={["4px", "15px", "12px", "32px"]}
      p={["3px", "20px", "12px", "15px"]}
      // bgColor={color + '.300'}
      // boxShadow='2xl green.400'
      // boxShadow={boxShadows[color] ? boxShadows[color] : ["10px 5px 12px #636adf" , "10px 25px 12px #636adf"]}
      rounded="lg"
      borderRadius="20px"
      alignSelf="center"
      {...colors[color]}
      flexDirection='column'
    >
      <Box flex={1}>
        <chakra.h3

          fontSize={["1em", "20px", "1.2em", "1em", "30px", "35px"]}
          fontWeight="bold"
          textAlign="center"
          mt={2}
          color="white"
        >
          {title}
        </chakra.h3>
        <chakra.p
          px={["1px", "2px", "1em", "0.8em", "3px"]}
          fontSize={["10px", "0.8em", "0.8em", "0.8em", "18px", "20px"]}

          mt={2}
          textAlign="center"
          color="white"
          noOfLines={[3, 4, 6, 4]}

        >
          {desc}
        </chakra.p>
      </Box>
      <Box align="center" justify="center" my='8px'>
        <Button as={Link} to={link}
          size={["sm", "md"]}
          fontSize={["10px", "10px", "15px", "0.8em", "18px", "20px"]}
          // bg="#F3904F"
          rounded="md"
          p={["5px", "6px", "6px", "8px"]}
          _hover={{
            bg: color + '.200',
            color: color + '.700'
          }}
          bgColor={color + '.100'}
          colorScheme={color}
          variant='ghost'
        >
          View more
        </Button>
      </Box>
    </Flex>
  )
}


export default ViewCards
