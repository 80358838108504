import React from "react";
import { Flex, Box, chakra, Spacer, Button, useBreakpointValue, useTheme } from "@chakra-ui/react";
import { ArrowForwardIcon } from "@chakra-ui/icons";
import getData, { keys } from "../../data";

const EnrollNow = ({ props }) => {
  const buttonSize = useBreakpointValue(["xs", "sm", "md", "lg"]);
  const theme = useTheme()
  let coachingWebsite = props.data.school.website_template == 2
  return (
    <Flex w="full" flexWrap="wrap">
      <Box
        mx="0px"
        w="full"
        bg={theme.primary}
      >
        <Flex
          columns="2"
          spacing={40}
          flexWrap="wrap"
          py={{ base: 5, lg: 16 }}
          px={{ base: 2, lg: 8 }}
        >
          <Box justify="flex-start" alignSelf="center">
            <chakra.h2
              mx="20px"
              fontSize={["2xl", "4xl", "2xl", "3xl"]}
              fontWeight="extrabold"
              letterSpacing="tight"
              lineHeight="shorter"
              color="white"
              mb={6}
            >
              <chakra.span display="block">
                {coachingWebsite ?
                  ' How to enroll yourself to a course ?'
                  :
                  ' How to enroll your child to Class ?'
                }
              </chakra.span>
            </chakra.h2>
            <chakra.h5
              mx="20px"
              fontSize={["lg", "lg", "lg", "xl"]}
              letterSpacing="tight"
              lineHeight="shorter"
              color="white"
              mb={6}
            >
              <chakra.span
                display="block"
                mx="auto"
              >
                {
                  coachingWebsite ?
                    `Register here and start you awesome journey with us.`
                    :
                    ` Interested in good education for your child ? Our
                  institute is the right place.`
                }

              </chakra.span>
            </chakra.h5>
          </Box>

          <Box>
            <Button

              border="solid transparent"
              fontWeight="bold"
              alignSelf="center"
              rounded="md"
              color={theme.primary}
              w={["20em", "20em", "20em", "15em"]}
              mx={["1.5em", "1em", "1em", "4em", "4em", "8em"]}
              h="3em"
              bg="white"
              size={buttonSize}
              rightIcon={<ArrowForwardIcon />}
              _hover={{
                opacity: '0.8'
              }}
              onClick={() =>
                window.open(
                  getData(props.data.school, keys.LOGIN_STUDENT_LINK) ||
                    "http://digital.schoollog.in"
                )
              }

              p={["8px", "5px"]}
            >
              Enroll now
            </Button>

          </Box>

        </Flex>
      </Box>
      <Spacer />
    </Flex>
  );
};

export default EnrollNow;
