import React from "react";
import { Flex, Box, chakra, Spacer, Button, useBreakpointValue, useTheme } from "@chakra-ui/react";
import { ArrowForwardIcon } from "@chakra-ui/icons";
import { motion } from "framer-motion";
import { Link } from "react-router-dom";
const EnrollNow = () => {
  const buttonSize = useBreakpointValue(["xs", "sm", "md", "lg"]);
  const theme = useTheme()
  const MotionButton = motion(Button)
  return (
    <Flex w="full" flexWrap="wrap">
      <Box
        mx="0px"
        w="full"
        bg={theme.primary}
      >
        <Flex
          columns="2"
          spacing={40}
          flexWrap="wrap"
          py={{ base: 5, lg: 16 }}
          px={{ base: 2, lg: 8 }}
        >
          <Box justify="flex-start" alignSelf="center">
            <chakra.h2
              mx="20px"
              fontSize={["2xl", "4xl", "2xl", "3xl"]}
              fontWeight="extrabold"
              letterSpacing="tight"
              lineHeight="shorter"
              color="white"
              mb={6}
            >
              <chakra.span display="block">
                How to enroll your child to Class ?
              </chakra.span>
            </chakra.h2>
            <chakra.h5
              mx="20px"
              fontSize={["lg", "lg", "lg", "xl"]}
              letterSpacing="tight"
              lineHeight="shorter"
              color="white"
              mb={6}
            >
              <chakra.span
                display="block"
                mx="auto"
              >
                Interested in good preschool education for your child ? Our
                kindegarden is the right place.
              </chakra.span>
            </chakra.h5>
          </Box>

          <MotionButton

            border="solid transparent"
            as={Link}
            to='/admission/online'
            fontWeight="bold"
            alignSelf="center"
            rounded="md"
            color={theme.primary}
            w={["20em", "20em", "20em", "15em"]}
            mx={["1.5em", "1em", "1em", "4em", "4em", "8em"]}
            h="3em"
            bg="white"
            size={buttonSize}
            rightIcon={<ArrowForwardIcon />}
            as={Link}
            to='/admission/online'
            p={["8px", "5px"]}
            whileHover={{ scale: 1.1, opacity: '0.9' }}
            whileTap={{ scale: 0.9 }}
          >
            Enroll now
          </MotionButton>
        </Flex>
      </Box>
      <Spacer />
    </Flex>
  );
};

export default EnrollNow;
