import {
  Box,
  Button,
  Flex,
  FormControl,
  FormLabel,
  Input,
  Radio,
  RadioGroup,
  Stack,
  Text,
  Spacer
} from '@chakra-ui/react';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import ErrorChecker from '../common/ErrorChecker';
import MainContainer from '../components/MainContainer';
import {
  getStates,
  getStandards,
  getAdmissionFormRequestAction
} from '../redux/actions';
import STATUS from '../Constants';
import Select from 'react-select'
import { CustomDatePicker } from '../helper';
import getData, { keys } from '../data';
import { PRINT_TYPE, printHelper } from '../Constants';
import CustomEnquiryForm from '../components/CustomEnquiryForm'

const OnlineAdmission = props => {
  const dispatch = useDispatch();
  const {
    statesList,
    standardsList,
    getStandardsStatus,
    getStatesStatus,
    admissionFormStatus
  } = useSelector(state => ({
    standardsList: state.standards,
    statesList: state.states,
    getStandardsStatus: state.getStandards,
    getStatesStatus: state.getStates,
    admissionFormStatus: state.admissionForm,
  }));

  useEffect(() => {
    dispatch(getStates());
    dispatch(getStandards(props.data.school.id));
  }, []);

  const submitForm = e => {
    e.preventDefault();
    dispatch(getAdmissionFormRequestAction(new FormData(e.target)));
  };

  const resetForm = () => {
    document.getElementById('enquiryForm').reset();
  }

  const isKuhu = props.data.school.id == "3na1vgrvz5gk7RmO"
  const isCustomForm = props.data.school.enquirySettings?.data?.customFormActive;
  return (
    <MainContainer heading={props.heading ? "Online Admission": undefined}>
      <br />
      <ErrorChecker status={getStatesStatus}>
        <ErrorChecker status={getStandardsStatus}>
          {getStatesStatus == STATUS.SUCCESS &&
            getStandardsStatus == STATUS.SUCCESS ? (
              isCustomForm ?
            <CustomEnquiryForm school={props.data.school} enquiry={props.data.enquiry}/>
            :
            <form id="enquiryForm" onSubmit={e => submitForm(e)}>
              <Box px={{ base: 0, md: 4 }}>
                <Flex flexWrap='wrap'>
                  <Text fontSize="2xl" color="#2E4053" mb={4}>
                    Personal Details
                  </Text>
                  <Spacer />
                  {props.hidePrint ? 
                    null
                    :
                    <Box mb='10px'>
                      <Button colorScheme='blue' onClick={() => printHelper(PRINT_TYPE.ADMISSION_FORM, { enquiry: {}, school: props.data.school })}>Print Empty Admission Form</Button>
                    </Box>
                  }
                </Flex>

                <Flex flexWrap="wrap">
                  <CustomInput
                    title="Student's Name"
                    name="name"
                    width="66.66%"
                    required
                  />
                  <CustomInput title="Email" type="email" name="email" />
                  <Gender title="Gender" />
                  <DateOfBirth title="Date of Birth" name="dob" />
                  <CustomInput title="Aadhar Number" name="adhar_no" />
                  <CustomInput
                    title="Address"
                    width="100%"
                    name="address"
                    required
                  />
                  <CustomInput title="City" name="city" required={!isKuhu} />
                  <SelectState
                    title="State"
                    placeholder="Select State"
                    statesList={statesList}
                    required = {!isKuhu}
                  />
                  <CustomInput title="Pincode" name="pin" required={!isKuhu} />
                  <CustomInput title="Religion" name="religion" />
                  <CustomInput title="Category" name="category" />
                  <CustomInput title="Nationality" name="nationality" />
                  <SelectStandard standardsList={standardsList} title="Class" />
                  <Medium title="Medium" />
                  <CustomInput type='hidden' name="school_id" value={getData(props.data.school, keys.ID)} />
                  <CustomInput type='hidden' name="origin" value={2} />

                </Flex>
              </Box>
              <br />
              <br />
              <Box>
                <Text fontSize="2xl" color="#2E4053" mb={4}>
                  Parent/Guardian Details
                </Text>
                <Flex flexWrap="wrap">
                  <CustomInput
                    title="Father's Name"
                    name="father_name"
                    required
                  />
                  <CustomInput
                    title="Father's Occupation"
                    name="father_occupation"
                    required={!isKuhu}
                  />
                  <CustomInput
                    title="Father's Contact"
                    name="father_contact"
                    required
                  />
                  <CustomInput title="Mother's Name" name="mother_name" />
                  <CustomInput
                    title="Mother's Occupation"
                    name="mother_occupation"
                  />
                  <CustomInput title="Mother's Contact" name="mother_contact" />
                </Flex>
              </Box>
              <br />
              <br />
              <Box>
                <Text fontSize="2xl" color="#2E4053" mb={4}>
                  Previous School Details
                </Text>
                <Flex flexWrap="wrap">
                  <CustomInput title="Last Class" name="last_class" />
                  <CustomInput title="Last School Name" name="last_school" />
                  <CustomInput
                    title="Percentage/Grade"
                    name="last_school_percent"
                  />
                  <CustomInput
                    title="Result (Pass/Fail)"
                    name="last_school_result"
                  />
                </Flex>
              </Box>
              <br />
              <Flex justifyContent={{ base: 'center', md: 'left' }}>
                <Button type="submit" colorScheme="blue">
                  Submit
                </Button>&nbsp;&nbsp;
                {admissionFormStatus == STATUS.SUCCESS ?
                  <span>
                    <Button onClick={() => printHelper(PRINT_TYPE.ADMISSION_FORM, { enquiry: props.data.enquiry, school: props.data.school })} colorScheme="blue">
                      Print Form
                    </Button>&nbsp;&nbsp;
                    <Button colorScheme="green" onClick={resetForm}>
                      Reset
                    </Button>
                  </span>
                  : null
                }
              </Flex>
            </form>
          ) : null}
        </ErrorChecker>
      </ErrorChecker>
    </MainContainer>
  );
};

const Gender = ({ title }) => {
  return (
    <Box
      key={title}
      w={{ base: '100%', sm: '100%', lg: '33.33%' }}
      pr={8}
      pb={4}
    >
      <FormControl>
        <FormLabel>
          {title} <span style={{ color: 'red' }}>*</span>
        </FormLabel>
        <RadioGroup>
          <Stack spacing={4} direction="row">
            <Radio name="gender" value="male">
              Male
            </Radio>
            <Radio name="gender" value="female">
              Female
            </Radio>
          </Stack>
        </RadioGroup>
      </FormControl>
    </Box>
  );
};

const DateOfBirth = ({ title }) => {
  const [dob, setDob] = useState('')

  return (
    <Box
      key={title}
      w={{ base: '100%', sm: '100%', lg: '33.33%' }}
      pr={8}
      pb={4}
    >
      <FormControl>
        <FormLabel>
          {title} <span style={{ color: 'red' }}>*</span>
        </FormLabel>
        <CustomDatePicker getDate={date => setDob(date)} />
        <Input type='hidden' name='dob' value={dob} required />
      </FormControl>
    </Box>
  );
};

const SelectState = ({ title, statesList, required }) => {
  const [selectedState, selectState] = useState('')
  const options = statesList.map(state => ({ label: state.name, value: state.id }))
  return (
    <Box
      key={title}
      w={{ base: '100%', sm: '100%', lg: '33.33%' }}
      pr={8}
      pb={4}
    >
      <FormControl>
        <FormLabel>
          {title} {required ? <span style={{ color: 'red' }}>*</span> : null}
        </FormLabel>
        <Select placeholder="Select State" onChange={e => selectState(e)} name="state_id" options={options} />
      </FormControl>
    </Box>
  );
};

const Medium = ({ title }) => {
  const [medium, selectMedium] = useState('English')
  const options = [
    { value: 'English', label: 'English' },
    { value: 'Hindi', label: 'Hindi' }
  ]
  return (
    <Box
      key={title}
      w={{ base: '100%', sm: '100%', lg: '33.33%' }}
      pr={8}
      pb={4}
    >
      <FormControl>
        <FormLabel>{title}</FormLabel>
        <Select placeholder="Select Medium" onChange={e => selectMedium(e)} options={options} />
        <Input type='hidden' value={medium.value} name='medium' />
      </FormControl>
    </Box>
  );
};

const SelectStandard = ({ title, standardsList }) => {
  const [standard, selectStandard] = useState('')
  const options = standardsList.map(std => ({ label: std.name, value: std.id }))
  return (
    <Box
      key={title}
      w={{ base: '100%', sm: '100%', lg: '33.33%' }}
      pr={8}
      pb={4}
    >
      <FormControl>
        <FormLabel>
          {title} <span style={{ color: 'red' }}>*</span>
        </FormLabel>
        <Select placeholder="Select Class" onChange={e => selectStandard(e)} options={options} />
        <Input type='hidden' value={standard.value} name='standard_id' />
      </FormControl>
    </Box>
  );
};

const CustomInput = ({ title, type, width, placeholder, required, name, value }) => {
  return (
    <Box
      key={title}
      w={{ base: '100%', sm: '100%', lg: width || '33.33%' }}
      pr={8}
      pb={4}
    >
      <FormControl>
        <FormLabel>
          {title} {required && <span style={{ color: 'red' }}>*</span>}
        </FormLabel>
        <Input
          type={type || 'text'}
          name={name}
          required={required}
          placeholder={placeholder || title}
          defaultValue={value}
        />
      </FormControl>
    </Box>
  );
};

export default OnlineAdmission;
